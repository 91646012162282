<template>
  <div class="fin-filtro-periodo">
    <u-btn color="white" size="sm" class="app-btn text-blue-grey-5" no-caps>
      <span><u-icon name="columns" type="fa" class="m-r-xs"/> {{ active ? active.nome : '-' }}</span>
      <u-icon name="caret-down" class="m-l" type="fa" style="font-size: 14px !important;"/>
      <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left"
                 :offset="[0, 5]" style="min-width: 172px">
        <div>
          <ul>
            <li v-if="loading" class="flex items-center wrapper full-width"><sl-loading content="" class="size-18" /></li>
            <menu-options-item v-for="funil in funis" :checked="active && funil.id === active.id" :key="funil.id"
                               @click="$emit('mudarFunil', funil)" :label="funil.nome" close edit @edit="editarFunil"/>
            <div class="default-diviser"></div>
            <menu-options-item @click="$router.push({name: 'funil.novo'})" close>
              <template v-slot:content>
                <div class="flex items-center">
                  <u-icon class="fa-fw font-10" name="plus" type="fa" icon-style="light"/>
                  <span class="menu-label-content no-select m-l-n">Novo Funil</span>
                </div>
              </template>
            </menu-options-item>
            <menu-options-item v-if="active" @click="$router.push({name: 'funil.editar', params: {id: active.id}})" close>
              <template v-slot:content>
                <div class="flex items-center">
                  <u-icon class="fa-fw font-10" name="pen" type="fa" icon-style="light"/>
                  <span class="menu-label-content no-select m-l-n">Editar Funil</span>
                </div>
              </template>
            </menu-options-item>
          </ul>
        </div>
      </u-popover>
    </u-btn>
  </div>
</template>

<script>
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {UPopover} from "uloc-vue"
import SlLoading from "@/components/layout/components/Loading"
// import {list} from "@/domain/negocio/services/funil"

export default {
  name: "FunilSelect",
  props: ['active', 'loading', 'funis'],
  data() {
    return {
    }
  },
  components: {
    SlLoading,
    MenuOptionsItem,
    UPopover
  },
  computed: {
  },
  mounted() {
    // this.load()
  },
  methods: {
    /*load() {
      list(1, 50, '')
          .then(response => {
            console.log(response.data)
            this.funils = response.data.result
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },*/
    editarFunil(f) {
      console.log('Editando funil ', f)
    }
  }
}
</script>
